import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  //Plugin needed to keep state when reloading page
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    //Language default: spanish
    language: 'es'
  },
  mutations: {
    changeLanguage(state, n){
      state.language = n
    }
  },
  actions: {
  },
  modules: {
  }
})
