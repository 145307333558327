<template>
    <v-hover
        v-slot="{ hover }"
    >
        <a href="https://wa.me/5215624301121" target="_blank">
            <v-btn
                :color="hover ? 'havelockBlue' : 'white'"
                fab
                large
                dark
                class="v-btn--example"
                style="position: fixed; z-index: 10; right: 20px; bottom: 20px;"
            >
                <v-icon large :color="hover ? 'white' : 'havelockBlue'">mdi-whatsapp</v-icon>
            </v-btn>
        </a>
    </v-hover>
</template>

<script>
export default {
    name: "WhatsAppFab_Button"
}
</script>

<style scoped>

</style>