export const footer_es = [
    {
        home: 'INICIO',
        services: 'SERVICIOS',
        portfolio: 'PORTAFOLIO',
        faq: 'FAQ',
        blog: 'BLOG',
        contact: 'CONTACTO',
        slogan: 'DIGITALIZANDO IDEAS',
        privacy: 'Aviso de privacidad',
        copyright: 'ideen es una empresa de Ideen Marketing S.A. de C.V.',
    }
]

export const footer_en = [
    {
        home: 'HOME',
        services: 'SERVICES',
        portfolio: 'PORTFOLIO',
        faq: 'FAQ',
        blog: 'BLOG',
        contact: 'CONTACT',
        slogan: 'Digitalizing ideas',
        privacy: 'Privacy Policy',
        copyright: 'ideen is a Ideen Marketing S.A. of C.V. company'
    }
]