<template>
    <v-footer app absolute dark width="100%" color="ideenBlue">
        <v-card flat tile color="ideenBlue" class="py-0 px-0" min-width="100%">
            <!-- IDEEN LOGO -->
            <div class="text-center col">
                <img
                    src="../../assets/footer/logo-footer.png"
                    class="logo_format mt-10 mb-4"
                    alt="logo"
                >
            </div>

            <!-- COLORS BAR IMG -->
            <img
                src="../../assets/footer/color-bar.png"
                style="width: 100%"
                alt=""
            >

            <!-- SLOGAN -->
            <div class="text-center col col-12">
                <p id="footerSlogan">
                    {{ footer_locale[0]['slogan'] }}
                </p>
            </div>

            <!-- ROUTING BUTTONS -->
            <div class="text-center col col-12">
                <v-row
                    justify="center"
                    align="center"
                    xs-text-center
                    md-text-start
                    lg-text-start
                    class="mb-8"
                >
                    <!-- HOME FOOTER BUTTON -->
                    <router-link
                        to="/"
                        class="pa-2 footerButton"
                        v-if="currentRoute === 'HomeScreen'"
                    >{{ footer_locale[0]['home'] }}</router-link>
                    <router-link
                        to="/"
                        class="pa-2 footerButtonActive"
                        v-else
                    >{{ footer_locale[0]['home'] }}</router-link>

                    <!-- SERVICES FOOTER BUTTON -->
                    <router-link
                        to="/services"
                        class="pa-2 footerButton"
                        v-if="currentRoute === 'ServicesScreen'"
                    >
                        {{ footer_locale[0]['services'] }}
                    </router-link>
                    <router-link
                        to="/services"
                        class="pa-2 footerButtonActive"
                        v-else
                    >{{ footer_locale[0]['services'] }}</router-link>

                    <!-- PORTFOLIO FOOTER BUTTON -->
                    <router-link
                        to="/portfolio"
                        class="pa-2 footerButton"
                        v-if="currentRoute === 'PortfolioScreen'">
                        {{ footer_locale[0]['portfolio'] }}
                    </router-link>
                    <router-link
                        to="/portfolio"
                        class="pa-2 footerButtonActive"
                        v-else
                    >{{ footer_locale[0]['portfolio'] }}</router-link>

                    <!-- FAQ FOOTER BUTTON -->
                    <router-link
                        to="/faq"
                        class="pa-2 footerButton"
                        v-if="currentRoute === 'FAQScreen'"
                    >{{ footer_locale[0]['faq'] }}</router-link>
                    <router-link
                        to="/faq"
                        class="pa-2 footerButtonActive"
                        v-else
                    >{{ footer_locale[0]['faq'] }}</router-link>

                    <!-- BLOG FOOTER BUTTON -->
                    <router-link
                        to="/blog"
                        class="pa-2 footerButton"
                        v-if="currentRoute === 'BlogScreen'"
                    >{{ footer_locale[0]['blog'] }}
                    </router-link>
                    <router-link
                        to="/blog"
                        class="pa-2 footerButtonActive"
                        v-else
                    >{{ footer_locale[0]['blog'] }}</router-link>

                    <!-- CONTACT FOOTER BUTTON -->
                    <router-link
                        to="/contact"
                        class="pa-2 footerButton"
                        v-if="currentRoute === 'ContactScreen'"
                    >{{ footer_locale[0]['contact'] }}
                    </router-link>
                    <router-link
                        to="/contact"
                        class="pa-2 footerButtonActive"
                        v-else
                    >{{ footer_locale[0]['contact'] }}</router-link>
                </v-row>
            </div>

            <!-- CONTACT BUTTONS ROW -->
            <v-row class="mt-2 mb-4" align="center" justify="center" align-content="center">
                <!-- COMPANY INFO DATA COLUMN-->
                <v-col
                    cols="12" sm="12" xs="6"
                    align-content="center"
                    justify="center"
                    align="center"
                    xs-text-center
                    md-text-start
                    lg-text-start
                    lg="6"
                    md="6"
                >
                    <v-row justify="center" class="px-8">
                        <p style="font-size: 100%; font-family: Montserrat, sans-serif; opacity: .75;" class="footer_text">
                            <!-- COMPANY PHONE -->
                            <span id="phone">
                                <v-icon size="30px;" style="opacity: .75">mdi-phone</v-icon>
                                <a href="tel:5624301121" class="linkText">&nbsp;5624301121</a></span>
                            <br><br>

                            <!-- COMPANY LOCATION -->
                            <a href="https://goo.gl/maps/mYYWT4gKWVAqohKg8" target="_blank" class="linkText">
                                Torre Porsche, Carr. México-Toluca No. 5420 piso 25 El Yaqui, Cuajimalpa de Morelos, 05320 CDMX, México.
                            </a>
                        </p>
                    </v-row>

                </v-col>

                <!-- COMPANY SOCIAL MEDIA BUTTONS COLUMN-->
                <v-col
                    class="mt-lg-0 mt-6 px-lg-8 pr-16"
                    cols="12" sm="12" lg="6" md="6"
                    xs-text-center
                    md-text-start
                    lg-text-end
                    align="end"
                >
                    <!-- COMPANY SOCIAL MEDIA BUTTONS ROW #1 -->
                    <v-row>
                        <v-card-text>
                            <SocialsTemplate_Footer
                                v-for="(label, index) in socials1"
                                :key="index"
                                :icon="label.icon"
                                :link="label.link"
                            ></SocialsTemplate_Footer>
                        </v-card-text>
                    </v-row>

                    <!-- COMPANY SOCIAL MEDIA BUTTONS ROW #2 -->
                    <v-row>
                        <v-card-text>
                            <SocialsTemplate_Footer
                                v-for="(label, index) in socials2"
                                :key="index"
                                :icon="label.icon"
                                :link="label.link"
                            ></SocialsTemplate_Footer>
                        </v-card-text>
                    </v-row>
                </v-col>
            </v-row>

            <v-divider></v-divider>

            <!-- COPYRIGHT DATA ROW -->
            <v-card-text class="white--text">
                <v-container>
                    <v-row align="start" justify="start" align-content="start">
                        <!-- PRIVACY POLICY BUTTON -->
                        <v-col cols="12" lg="4" align="center" justify="center" align-content="center">
                            <router-link to="/privacy" class="copyrightText">
                                {{ footer_locale[0]['privacy'] }}
                            </router-link>
                        </v-col>

                        <!-- COPYRIGHT DATA -->
                        <v-col cols="12" lg="4" align="center" justify="center" align-content="center">
                            <p class="copyrightText">© ideen | México | {{ new Date().getFullYear() }}</p>
                        </v-col>

                        <!-- COMPANY LEGAL INFO -->
                        <v-col cols="12" lg="4" align="center" justify="center" align-content="center">
                            <p class="copyrightText">{{ footer_locale[0]['copyright'] }}</p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-footer>
</template>
<script>

import SocialsTemplate_Footer from '@/components/footer/SocialsTemplate_Footer'
import * as db from "./../../constants/footer_database"

export default {
    name: 'Template_Footer',
    components: {SocialsTemplate_Footer},
    data: () => ({
        footer_locale: null,
        socials1: [
            {
                icon: 'LinkedIn.png',
                link: 'https://www.linkedin.com/company/ideen-mx/mycompany/'
            },
            {
                icon: 'Instagram.png',
                link: 'https://www.instagram.com/ideen_mx/?hl=es-la'
            },
            {
                icon: 'Facebook.png',
                link: 'https://www.facebook.com/ideen01/'
            }
        ],
        socials2: [
            {
                icon: 'WhatsApp.png',
                link: 'https://wa.me/5215624301121'
            },
            {
                icon: 'Telegram.png',
                link: 'https://t.me/Ideen_mx'
            },
            {
                icon: 'Mail.png',
                link: 'mailto:benjamin.avila@ideen.mx'
            }
        ]
    }),
    computed: {
        //Gets current language
        language() {
            return this.$store.state.language
        },
        //Get current route
        currentRoute() {
            return this.$route.name
        }
    },
    beforeMount() {
        //Checks store value to import localized text
        if (this.language === 'es') {
            this.footer_locale = db.footer_es;
        } else {
            this.footer_locale = db.footer_en;
        }
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat');

.logo_format {
    width: 10%;
    height: 10%;
}

.linkText {
    text-decoration: none;
    color: #ECEFF1;
}

.copyrightText {
    font-family: Montserrat, sans-serif;
    text-decoration: none;
    color: #ECEFF1;
}

#footerSlogan {
    font-family: Montserrat, sans-serif;
    font-size:200%;
}

.footer_text {
    text-align: left
}

.footerButtonActive {
    font-family: Montserrat, sans-serif;
    text-decoration: none;
    font-size: 100%;
    color: #ECEFF1;
}

.footerButton {
    font-family: Montserrat, sans-serif;
    text-decoration: none;
    font-size: 100%;
    font-weight: bold;
    color: #FFF;
}

@media (max-width: 768px) {
    .logo_format {
        width: 50%;
        height: 50%;
    }

    #footerLinks {
        margin-left: 32%;
        margin-right: 32%;
    }

    .footer_text {
        text-align: center
    }
}

html, body {
    font-family: 'Montserrat', sans-serif;
}

#phone {
    font-size: 150%;
}


</style>
