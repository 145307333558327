<template>
    <v-btn class="mx-6 mx-lg-4 white--text" icon style="opacity: .75" v-bind:href="link" target="_blank">
      <v-img max-height="42px" max-width="42px" :src="require(`../../assets/socials/${icon}`)"></v-img>
    </v-btn>
</template>

<script>
export default {
  name: 'SocialsTemplate_Footer',
  props: {
    link: String,
    icon: String
  }
}
</script>

<style scoped>

</style>
