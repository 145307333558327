import firebase from "firebase/app";
import 'firebase/analytics'
import 'firebase/firestore'

let firebaseConfig = {
  apiKey: "AIzaSyAGwaMw2mArmTjfCCzApEqHlzy_zmP8z9w",
  authDomain: "ideenwebsite.firebaseapp.com",
  databaseURL: "https://ideenwebsite.firebaseio.com",
  projectId: "ideenwebsite",
  storageBucket: "ideenwebsite.appspot.com",
  messagingSenderId: "395655402699",
  appId: "1:395655402699:web:6a9b36231e3391d5b2b74e",
  measurementId: "G-H2YDHCDWBM"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const db = firebase.firestore();
