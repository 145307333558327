import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        black: "#464646",
        white: "#FFFFFF",
        purple: "#9961F2",
        ideenBlue: "#0131B7",
        ideenButton: "#545454",
        royalBlue: "#3F64D9",
        brightRoyalBlue: "#587DE3",
        havelockBlue: "#647CE0",
        jordyBlue: "#95A9F4",
        java: "#16CCCC",
        green: "#09B408",
        mango: "#FFB902",
        orange: "#FE7700",
        pink: "#FD0080",
      }
    }
  },
  icons: {
    iconfont: 'md',
  },
});
