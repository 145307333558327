import Vue from 'vue'
import VueRouter from 'vue-router'
const Services_Screen = () => import('./../views/services/Services_Screen')
const Portfolio_Screen = () => import('./../views/portfolio/Portfolio_Screen')
const PortfolioProduct_Screen = () => import('../views/portfolio_project/PortfolioProduct_Screen')
const Contact_Screen = () => import('./../views/contact/Contact_Screen')
const Faq_Screen = () => import('./../views/faq/Faq_Screen')
const Home_Screen = () => import('./../views/home/Home_Screen')
const Blog_Screen = () => import('./../views/blog/Blog_Screen')
const BlogEntry_Screen = () => import('../views/blog_entry/BlogEntry_Screen')

Vue.use(VueRouter)
/*
To insert a route for each screen you might follow this steps

1. Import for lazy loading your component --> const Home_Screen = () => import('./../views/Home_Screen')
2. Create a route object like this --> { path: '/', name: 'HomeScreen', component: Home_Screen }
3. Add this route object to the "routes" array.
4. You can use your screen route :)

For more info go to https://router.vuejs.org/

*/

const routes = [
  { path: '/', name: 'HomeScreen', component: Home_Screen },
  { path: '/portfolio', name: 'PortfolioScreen', component: Portfolio_Screen },
  { path: '/portfolio/:slug/:id', name: 'PortfolioProduct', component: PortfolioProduct_Screen, props: true},
  { path: '/services', name: 'ServicesScreen', component: Services_Screen },
  { path: '/services/:id', name: 'ServicesScreen', component: Services_Screen, props: true },
  { path: '/contact', name: 'ContactScreen', component: Contact_Screen },
  { path: '/faq', name: 'FAQScreen', component: Faq_Screen },
  { path: '/blog', name: 'BlogScreen', component: Blog_Screen },
  { path: '/blog/:id', name: 'BlogEntry', component: BlogEntry_Screen, props: true},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
