export const navbar_es = [
    {
        home: 'INICIO',
        services: 'SERVICIOS',
        portfolio: 'PORTAFOLIO',
        faq: 'FAQ',
        blog: 'BLOG',
        contact: 'CONTACTO'
    }
]

export const navbar_en = [
    {
        home: 'HOME',
        services: 'SERVICES',
        portfolio: 'PORTFOLIO',
        faq: 'FAQ',
        blog: 'BLOG',
        contact: 'CONTACT'
    }
]