<template>
    <div>
        <!-- Desktop navigation menu -->
        <v-app-bar
            elevation="0"
            color="white"
            app
        >
            <!-- NAVBAR DESKTOP LOGO -->
            <router-link v-if="!mobile" to="/">
                <v-img max-height="100" max-width="120"
                       src="../../assets/brand/Logo_ideen.png"
                       class="mb-2 ml-16">
                </v-img>
            </router-link>

            <!-- NAVBAR MOBILE LOGO -->
            <router-link v-if="mobile" to="/">
                <v-img max-width="80"
                       src="../../assets/brand/Logo_ideen.png"
                       class="mb-2 ml-3">
                </v-img>
            </router-link>

            <!-- LANGUAGE SELECTION BUTTONS -->
            <v-btn-toggle borderless elevation="0" color="ideenBlue">
                <v-btn text depressed class="ml-4 pa-3 languageButton" @click="setSpanish" color="ideenBlue" v-if="language === 'es'">ESPAÑOL</v-btn>
                <v-btn text depressed class="ml-4 pa-3 languageButton" @click="setSpanish" v-if="language === 'en'">ESPAÑOL</v-btn>
                <v-btn text depressed class="pa-3 languageButton" @click="setEnglish" color="ideenBlue" v-if="language === 'en'">ENGLISH</v-btn>
                <v-btn text depressed class="pa-3 languageButton" @click="setEnglish" v-if="language === 'es'">ENGLISH</v-btn>
            </v-btn-toggle>

            <v-spacer></v-spacer>

            <!-- NAVBAR BUTTONS -->
            <v-btn-toggle borderless elevation="0" color="ideenBlue">
                <v-btn text v-if="!mobile" to="/" class="pa-3">{{ navbar_locale[0]['home'] }}</v-btn>
                <v-btn text v-if="!mobile" to="/services" class="pa-3">{{ navbar_locale[0]['services'] }}</v-btn>
                <v-btn text v-if="!mobile" to="/portfolio" class="pa-3">{{ navbar_locale[0]['portfolio'] }}</v-btn>
                <v-btn text v-if="!mobile" to="/faq" class="pa-3">{{ navbar_locale[0]['faq'] }}</v-btn>
                <v-btn text v-if="!mobile" to="/blog" class="pa-3">{{ navbar_locale[0]['blog'] }}</v-btn>
                <v-btn text v-if="!mobile" to="/contact" class="mr-16 pl-3">{{ navbar_locale[0]['contact'] }}</v-btn>
            </v-btn-toggle>

            <!-- OPEN DRAWER BUTTON -->
            <v-app-bar-nav-icon v-if="mobile" @click.stop="drawerTrigger = !drawerTrigger">
                <v-icon color="ideenBlue">menu</v-icon>
            </v-app-bar-nav-icon>
        </v-app-bar>

        <!--Mobile menu-->
        <v-navigation-drawer
            v-model="drawerTrigger"
            width="100%"
            temporary
            right
            app
        >
            <!-- CLOSE BUTTON ROW -->
            <v-row justify="end" align="end" class="mt-3 mr-3">
                <v-btn @click="drawerTrigger = false;" icon>
                    <v-icon color="ideenBlue">close</v-icon>
                </v-btn>
            </v-row>

            <!-- NAV ITEMS LIST -->
            <v-list nav dense>
                <v-list-item-group>
                    <!-- HOME BUTTON -->
                    <router-link style="text-decoration: none" to="/">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-btn block text depressed color="ideenBlue" class="pa-2">{{ navbar_locale[0]['home'] }}</v-btn>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                    <!-- SERVICES LIST TRIGGER BUTTON -->
                    <v-list-item class="pa-0 ma-0">
                        <v-list-item-content class="pa-0 ma-0">
                            <!-- Use of expansion panels for sub-menu "SERVICIOS"-->
                            <v-expansion-panels flat tile accordion>
                                <v-expansion-panel class="pa-0 ma-0">
                                    <v-expansion-panel-header hide-actions class="pa-0 ma-0">
                                        <v-btn block text depressed color="ideenBlue">{{ navbar_locale[0]['services'] }}</v-btn>
                                    </v-expansion-panel-header>

                                    <v-expansion-panel-content>
                                        <!-- Display button per service -->
                                        <v-btn
                                            v-for="(item, index) in services" v-bind:key="index"
                                            @click="goToService(index)"
                                            class="mb-3"
                                            color="#888"
                                            depressed
                                            block
                                            text
                                        >
                                            {{ services[index]['formattedTitle'] }}
                                        </v-btn>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-list-item-content>
                    </v-list-item>

                    <!-- PORTFOLIO BUTTON -->
                    <router-link style="text-decoration: none" to="/portfolio">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-btn block text depressed color="ideenBlue" class="pa-2">{{ navbar_locale[0]['portfolio'] }}</v-btn>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                    <!-- FAQ BUTTON -->
                    <router-link style="text-decoration: none" to="/faq">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-btn block text depressed color="ideenBlue" class="pa-2">{{ navbar_locale[0]['faq'] }}</v-btn>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                    <!-- BLOG BUTTON -->
                    <router-link style="text-decoration: none" to="/blog">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-btn block text depressed color="ideenBlue" class="pa-2">{{ navbar_locale[0]['blog'] }}</v-btn>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                    <!-- CONTACT BUTTON -->
                    <router-link style="text-decoration: none" to="/contact">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-btn block text depressed color="ideenBlue" class="pa-2">{{ navbar_locale[0]['contact'] }}</v-btn>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <div v-if="!mobile" class="bottomBarColor"></div>
    </div>
</template>

<script>
import * as db from "./../../constants/services_database"
import * as navbar_db from "./../../constants/navbar_database"

export default {
    name: 'NavBar',
    data() {
        return {
            navbar_locale: null,
            drawerTrigger: false,
            mobile: false,
            services: []
        }
    },
    computed: {
        //Gets current language
        language() {
            return this.$store.state.language
        }
    },
    beforeMount() {
        //Checks store value to import localized text
        if (this.language === 'es') {
            this.navbar_locale = navbar_db.navbar_es;
            this.services = db.services_info_es;
        } else {
            this.navbar_locale = navbar_db.navbar_en;
            this.services = db.services_info_en;
        }
    },
    created() {
        this.setScreenSize()
        window.addEventListener('resize', this.setScreenSize)
    },
    methods: {
        setSpanish() {
            //Check if language is not spanish to change the store value to 'es'
            if (this.language !== 'es') {
                this.$store.commit('changeLanguage', 'es')
                location.reload()
            }
        },

        setEnglish() {
            //Check if language is not english to change the store value to 'en'
            if (this.language !== 'en') {
                this.$store.commit('changeLanguage', 'en')
                location.reload()
            }
        },

        setScreenSize() {
            this.mobile = window.innerWidth <= 960
        },

        goToService(index) {
            let selected = index.toString()
            this.$router.push({path: '/services', query: {service: selected}})
        },
    },
}
</script>

<style scoped>
.bottomBarColor {
    border-bottom: 80px solid #0131B7;
    position: fixed;
    width: 100%;
    z-index: 2;
    height: 8%;
    top: 0;
}

.v-btn::before {
    background-color: transparent;
}

.v-btn:hover {
    background-color: #D0DBF9;
    color: #0131B7;
}

@media (max-width: 600px) {
    .languageButton {
        font-size: 0.70em !important;
    }
}
</style>