export const services_info_es = [
  {
    title: "APPS MÓVILES",
    formattedTitle: "APPS MÓVILES",
    class: "mobile",
    color: "#09B408",
    image: "appMovil.png",
    text1: "Las aplicaciones móviles son programas que ofrecen algún servicio que tiene como intención estar disponible 24/7 desde el celular. " +
        "Las marcas cada vez están más interesadas en desarrollar este tipo de apps para aprovechar sus beneficios principales; aumentar fidelidad " +
        "de clientes, reforzar presencia de marca, incrementar visibilidad y accesibilidad de los servicios que ofrecen e incluso incrementar sus" +
        " ventas directamente desde estas apps.",
    text2: "En ideen somos especialistas en desarrollar aplicaciones que ayudan a empresas pequeñas o grandes a alcanzar sus objetivos utilizando " +
        "a su favor las tecnologías más innovadoras disponibles en el mercado. Ya sea que busques desarrollar una app que te permita optimizar " +
        "tu operación interna, ofrecer un nuevo servicio al público, complementar productos o servicios físicos que ya ofrezcas. tenemos al " +
        "equipo perfecto para transformar cualquier idea desde el concepto a la realidad. Hoy en día el mercado es demasiado exigente y necesita " +
        "tener tu mobile app en su dispositivo sin importar la marca, pero no te preocupes, nosotros nos encargamos de desarrollar y desplegar " +
        "tu app para Android y iOS.",
    tech: ["Apple.png", "Firebase.png", "Flutter.png","Android.png", "GoogleCloud_H.png",],
    icons: ["phone_iphone", "book_online"],
    techSubtitle: "NUESTRA ESPECIALIDAD ES DESARROLLAR MOBILE APPS CON",
  },
  {
    title: "WEB APPS",
    formattedTitle: "WEB APPS",
    class: "web",
    color: "#FE7700",
    image: "webApp.png",
    text1: "Hace unos cuantos años el contenido web era únicamente estático con un par de fotos, estilos y animaciones pero siempre visualizabas lo" +
        " mismo dentro de una página web. Una Aplicación Web o Web App, es un programa computacional que te permite acceder a un servicio digital " +
        "desde un navegador de internet; blogs, plataformas de streaming, ERPs, redes sociales y más.",
    text2: "Las web apps te permiten diseñar experiencias digitales únicas, como en las apps móviles, pero a estas le sumas la versatilidad de que " +
        "cualquier dispositivo con conexión a internet puede correr tu app desde el navegador, sin importar si es un celular, una tablet o una " +
        "computadora o la marca. El equipo de ideen se ha vuelto experto en desarrollar un sin fin de web apps con algunas de las tecnologías" +
        " más recientes del mercado para siempre ofrecer una experiencia innovadora y con larga esperanza de vida.",
    tech: ["CSS3.png", "Vue.png", "html-5.png", "GoogleCloud_H.png", "Firebase_H.png",],
    icons: ["web", "devices"],
    techSubtitle: "SOMOS ESPECIALISTAS DESARROLLANDO PRODUCTOS CON",
  },
  {
    title: "WEBSITES",
    formattedTitle: "WEBSITES",
    class: "websites",
    color: "#FFB902",
    image: "website.png",
    text1: "A través de los años las empresas, asociaciones, figuras públicas, entre otros, necesitan más canales de comunicación para lograr impactar la mayor cantidad de gente posible. Una de las herramientas de comunicación más solicitadas han sido las páginas web profesionales, esto se debe al gran alcance que puede llegar a tener con una inversión mucho menor a medios de comunicación tradicionales como la imprenta, radio, etc., teniendo una relación 'costo-beneficio' mucho mejor que antiguas soluciones.",
    text2: "A pesar de que cada día la tecnología nos hace las cosas más simples, puede ser muy complicado para un negocio capitalizar estas oportunidades si no se tiene experiencia profesional en manejo de herramientas de creación de websites. Pero no te preocupes, para eso estamos nosotros. Con una amplia experiencia en diseño de UX/UI, branding, marketing y desarrollo web, el equipo de ideen creará una experiencia única e innovadora que marcará la diferencia entre tu negocio y la competencia.",
    tech: ["CSS3.png", "JavaScript.png", "html-5.png", null, null],
    icons: ["language", "public"],
      techSubtitle: "SOMOS ESPECIALISTAS DESARROLLANDO WEBSITES CON",
  },
  {
    title: "E-COMMERCE",
    formattedTitle: "E-COMMERCE",
    class: "ecommerce",
    color: "#16CCCC",
    image: "eCommerce.png",
    text1: "Como secuela de pandemia, hoy en día nos enfrentamos a un escenario sin precedentes de la compra-venta de productos y servicios por " +
        "Internet. Según datos de la Asociación Mexicana de Venta Online (AMVO), el 39% de los mexicanos adquiere activamente productos o servicios" +
        " en línea y se estima un crecimiento del 50% para finales de 2022 respecto al año anterior, lo que quiere decir que es el momento ideal para " +
        "que decidas incursionar en el mundo del e-commerce, comiences a vender tus productos a través de Internet y abras tu mercado a todo México " +
        "e incluso otros países.",
    text2: "Para darle solución a tus necesidades, ideen está verificado como Shopify Partner, lo que quiere decir que estamos avalados por esta " +
        "plataforma como una de las agencias de desarrollo especializada en construcción de tiendas en línea. Esto nos permite dar solución a todas " +
        "tus necesidades para que puedas comercializar y llevar tus productos hasta el hogar de tus clientes lo más pronto y fácil posible.",
    tech: ["JavaScript.png", "CSS3.png", "html-5.png", "Shopify.png", null,],
    icons: ["storefront", "sell"],
    techSubtitle: "SOMOS ESPECIALISTAS DESARROLLANDO TIENDAS ONLINE CON",
  },
  {
    title: "MARKETING DIGITAL <br>ORGÁNICO",
    formattedTitle: "MARKETING DIGITAL ORGÁNICO",
    class: "desktop",
    color: "#FD0080",
    image: "marketingDigital.png",
    text1: "Sabemos de lo importante que es para ti y tu empresa formar una comunidad de amantes de tu marca, que conozcan tus productos o servicios, " +
        "pero que además entiendan tu visión y se identifiquen con tu esencia. Utilizamos todo el poder de las redes sociales a nuestro favor para " +
        "crear conexiones reales con tus seguidores, para que a partir de ahí, estos mismos te puedan hablar, escuchar y estar al pendiente de lo " +
        "próximo que tengas por decirles.",
    text2: "El equipo creativo de ideen define la estrategia única para tu marca que logre educar, vender y ofrecer valor a tu audiencia. Nuestras " +
        "campañas de marketing orgánico están compuestas por un ciclo de pasos que nos permiten lograr los objetivos de tu marca; entendemos tu marca, " +
        "analizamos su audiencia, seleccionamos canales de comunicación, investigamos tendencias, planeamos y generamos contenido, incentivamos la " +
        "interacción y al finalizar, medimos los resultados para tener una mejora continua en nuestro trabajo.",
    tech: ["Facebook.png", "Instagram.png", "Linkedin.png", null, null,],
    icons: ["campaign", "tips_and_updates"],
    techSubtitle: "SOMOS ESPECIALISTAS DISEÑANDO ESTRATEGIAS PARA",
  },
  {
    title: "MARKETING DIGITAL <br>PAUTADO",
    formattedTitle: "MARKETING DIGITAL PAUTADO",
    class: "marketing",
    color: "#9961F2",
    image: "marketingPauta.png",
    // image: "marketingDigital.png",
    text1: "Una vez que tenemos la estrategia de marketing definida, apoyamos esta con diferentes canales de pago para llevar tráfico calificado a tus " +
        "redes sociales, website, e-commerce o aplicación, con esto logramos que cada vez más personas afines a la identidad o intereses de tu marca, " +
        "se enamoren de tu trabajo. Llegamos a los usuarios con un alto potencial de compra por medio de anuncios con diferentes formatos en motores " +
        "de búsqueda, redes sociales, herramientas de programmatic marketing e incluso podemos combinarlo con medios de comunicación tradicionales.",
    text2: " Las campañas pautadas de marketing digital que diseñamos e implementamos en ideen te ayudan a reducir el Costo de Adquisición por " +
        "Cliente (CAC), aumentar la frecuencia de compra de tus clientes, además te presentamos reportes detallados de tu inversión para conocer " +
        "el rendimiento de tu campaña.",
    tech: ["Facebook.png", "Instagram.png", "Linkedin.png", "GoogleAds_H.png", "Simpli.fi.png",],
    icons: ["currency_exchange", "ads_click"],
    techSubtitle: "SOMOS ESPECIALISTAS IMPLEMENTANDO CAMPAÑAS EN",
  },
  {
    title: "BRANDING <br>& DESIGN",
    formattedTitle: "BRANDING & DESIGN",
    class: "branding",
    color: "#3F64D9",
    image: "branding&Design.png",
    text1: "No hay marca sin personalidad, pero hay que construirla. Para lograr esto, un simple gráfico no es suficiente; requieres un logotipo con " +
        "la capacidad de ser reconocible, flexible, recordable y que quiera ser una marca que enamore.",
    text2: "En ideen creamos tu nueva identidad de marca desde cero o rediseñamos el logotipo que tienes en uso. Pero no solamente eso, también realizamos " +
        "la personalización de tu marca trabajando con un universo visual que la lleve a ser única.",
    tech: ["Photoshop.png", "Illustrator.png", "InDesign.png", null, null],
    icons: ["brush", "palette"],
    techSubtitle: "SOMOS ESPECIALISTAS CREANDO ARTE DIGITAL CON",
  }
]

export const services_info_en = [
  {
    title: "MOBILE APPS",
    formattedTitle: "MOBILE APPS",
    class: "mobile",
    color: "#09B408",
    image: "appMovil.png",
    text1: "Mobile apps are software pieces that offer a service that needs to be available 24/7 from our mobile devices whether it is a phone or a " +
        "tablet. Too many brands and companies nowadays require to offer a service through this kind of apps and exploit its capabilities to do " +
        "things such as increase customer loyalty, expanding brand footprint, increase visibility and offer different or innovative ways to offer " +
        "existing services always focusing on increasing revenue.",
    text2: "ideen as a team has become specialist developing apps that helps SMBs or corporate companies to achieve their goals using the most " +
        "recent and powerful technologies available on market. Whether you need a platform that allow your company to improve internal processes, " +
        "offer a new service to a community or improve existing physical products or services, we are the perfect team to turn your ideas into " +
        "reality. Nowadays market is extremely demanding and needs to have your product or service through a mobile app that is available on " +
        "all platforms, but do not worry about that, we cover you from the very beginning of the project until its release on Apple App Store " +
        "and Google PlayStore.",
    tech: ["Apple.png", "Firebase.png", "Flutter.png","Android.png", "GoogleCloud_H.png",],
    icons: ["phone_iphone", "book_online"],
    techSubtitle: "WE ARE SPECIALISTS DEVELOPING MOBILE APPS WITH",
  },
  {
    title: "WEB APPS",
    formattedTitle: "WEB APPS",
    class: "web",
    color: "#FE7700",
    image: "webApp.png",
    text1: "Some years ago, web content was totally static, without any custom content based on user, it was just some plain text, photos, content " +
        "beautifying tools and animations working together but content never used to change. Modern Web Apps, as known nowadays, are platforms that " +
        "allow you to access to digital services through an internet browser such as blogs, streaming platforms, CRMs, ERPs, social media, among others.",
    text2: "Web Apps allow companies to create unique digital experiences, such as mobile apps, but in this case, this kind of apps add some more " +
        "versatility to be used on any device with an internet connection and a browser, without worrying about if the used device is a mobile phone, " +
        "a tablet or a computer, neither its brand.",
    tech: ["CSS3.png", "Vue.png", "html-5.png", "GoogleCloud_H.png", "Firebase_H.png",],
    icons: ["web", "devices"],
    techSubtitle: "WE ARE SPECIALISTS DEVELOPING WEB APPS WITH",
  },
  {
    title: "WEBSITES",
    formattedTitle: "WEBSITES",
    class: "websites",
    color: "#FFB902",
    image: "website.png",
    text1: "As technology evolves companies, associations, public figures and more, need more communication channels than ever to impact as many " +
        "people is possible. One of the most needed communication tools on this century have been websites, this software pieces are still on top " +
        "of the best communication channels thanks to the ease to reach lots of people with a considerably lower investment compared to traditional " +
        "media, this way, websites have become one of the most efficient ways to get people to know about your products & services without braking " +
        "the bank.",
    text2: "We know technology haven't ever been so easy to use as now, but stills can be tricky for a company to capitalize this opportunities if " +
        "you do not have enough experience managing websites generation tools. It is impossible to master everything we need to grow a business, " +
        "that is the reason why we can become your best ally with great experience on UX/UI, marketing and web development, our team can build a " +
        "unique and innovative experience that will make you stand out from competitors.",
    tech: ["CSS3.png", "JavaScript.png", "html-5.png", null, null],
    icons: ["language", "public"],
    techSubtitle: "WE ARE SPECIALISTS BUILDING WEB APPS WITH",
  },
  {
    title: "E-COMMERCE",
    formattedTitle: "E-COMMERCE",
    class: "ecommerce",
    color: "#16CCCC",
    image: "eCommerce.png",
    text1: "As a pandemic consequence, we are currently facing a completely new scenario on the way we buy and sell" +
      " products and services through digital channels. Based on the “Asociación Mexicana de Venta Online (AMVO)” data," +
      " 39% of Mexicans actively purchase products or services online via e-commerce and it is estimated that this " +
      "number is going to increase up to 50% during the rest of this year. This means we are in the ideal moment to " +
      "build an e-commerce for your company to offer.",
    text2: "In order to offer a solution to your needs, ideen is verified as a Shopify Partner; this means that we are" +
      " licensed by this software as one of the development agencies that are experts in the creation of e-commerce" +
      " platforms. This allows us to offer the solutions to any need you have regarding the commercialization of " +
      "your products or services, guaranteeing a friendly user experience for your clients.",
    tech: ["JavaScript.png", "CSS3.png", "html-5.png", "Shopify.png", null,],
    icons: ["storefront", "sell"],
    techSubtitle: "WE ARE SPECIALISTS CREATING E-COMMERCE WEBSITES WITH",
  },
  {
    title: "ORGANIC <br> MARKETING",
    formattedTitle: "ORGANIC <br> MARKETING",
    class: "desktop",
    color: "#FD0080",
    image: "marketingDigital.png",
    text1: "We know how important it is for you and your business to build a community formed by people who love your " +
      "products or services, get what your vision is all about, and strongly identify with your brands’ essence. We " +
      "use all the social networks power in our favor to create real bonds with your followers, allowing them to talk" +
      " to you, listen to you, and stay aware of whatever news you have to tell them.",
    text2: "The ideen team can build a unique brand strategy, that will help you inform, sell, and generate value to " +
      "your audience. Our organic marketing campaigns are build by a number of steps that allow us to achieve your " +
      "brand’s goals; we understand the brand, analyze its audience, choose communication channels, look up for " +
      "actual tendencies, plan and generate content, push for audience interaction, and at the end, measure the " +
      "results in order to continue getting better for next campaign.",
    tech: ["Facebook.png", "Instagram.png", "Linkedin.png", null, null,],
    icons: ["campaign", "tips_and_updates"],
    techSubtitle: "WE ARE SPECIALISTS CREATING STRATEGIES WITH",
  },
  {
    title: "PAID <br> MARKETING",
    formattedTitle: "PAID MARKETING",
    class: "marketing",
    color: "#9961F2",
    image: "marketingPauta.png",
    // image: "marketingDigital.png",
    text1: "Once the marketing strategy is finished, we use a variety of paid channels to boost qualified traffic towards " +
      "your social network profiles, website, e-commerce, or app, this way we are able to reach the audience that are " +
      "interested in or relate to your brand identity making them fall in love with your brand. This audience are " +
      "the ones that, based on their likes and behavior, are more likely to buy through digital advertisements that " +
      "present to them in different formats through all the search engines, social network platforms, programmatic " +
      "tools, and even through traditional marketing channels.",
    text2: "The paid campaigns we design and implement at ideen will help you reduce the Customer Acquisition Cost " +
      "(CAC), and increase your clients’ buying frequency. Also, we prepare detailed reports regarding the " +
      "results of the campaign based on the investment you made for it.",
    tech: ["Facebook.png", "Instagram.png", "Linkedin.png", "GoogleAds_H.png", "Simpli.fi.png",],
    icons: ["currency_exchange", "ads_click"],
    techSubtitle: "WE ARE SPECIALISTS IMPLEMENTING CAMPAIGNS THROUGH",
  },
  {
    title: "BRANDING <br>& DESIGN",
    formattedTitle: "BRANDING & DESIGN",
    class: "branding",
    color: "#3F64D9",
    image: "branding&Design.png",
    text1: "It is almost impossible to find a brand without its own identity, but in order to have an identity, " +
      "you have to build it. To achieve this, a simple graphic design is not enough. It is imperative for your " +
      "brand to have a good logo that not only makes sense, but that is flexible, memorable, easy to identify," +
      " and likable to your target.",
    text2: "EAt ideen we build your new identity from scratch, or we refresh the one you already have. But" +
      " that is not it! We can also personalize your brand using a whole visual strategy that makes it a unique one.",
    tech: ["Photoshop.png", "Illustrator.png", "InDesign.png", null, null],
    icons: ["brush", "palette"],
    techSubtitle: "WE ARE SPECIALISTS CREATING DIGITAL ART WITH",
  }
]

export const services_media_es = [
  {
    techSubtitle:"SOMOS ESPECIALISTAS CREANDO PRODUCTOS CON",
  }
]

export const services_media_en = [
  {
    techSubtitle:"WE ARE SPECIALISTS CREATING PRODUCTS WITH",
  }
]

export const button_es = [
  {
    text: 'PONTE EN CONTACTO'
  }
]

export const button_en = [
  {
    text: 'CONTACT US'
  }
]