<template>
    <v-app>
        <NavBar></NavBar>

        <v-main>
            <WhatsAppFab_Button></WhatsAppFab_Button>

            <router-view :key="$route.fullPath"></router-view>
        </v-main>

        <Template_Footer></Template_Footer>
    </v-app>
</template>

<script>
import Template_Footer from '@/components/footer/Template_Footer'
import NavBar from "@/components/navbar/NavBar";
import "@/styles/main.css"
import firebase from "firebase";
import WhatsAppFab_Button from "@/components/buttons/WhatsAppFab_Button";

export default {
    name: 'App',
    components: {
        WhatsAppFab_Button,
        Template_Footer,
        NavBar,
    },
    data() {
        return {}
    },
    beforeMount() {
        firebase.auth().signInAnonymously().then(() => {
            console.log("Welcome :)")
        });
    },
};
</script>

<style>
</style>